import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyByiaL0ER3OXNeTsRpmCQMWBjrYK86uEX0",
  authDomain: "footballminiger.firebaseapp.com",
  projectId: "footballminiger",
  storageBucket: "footballminiger.appspot.com",
  messagingSenderId: "1066757692805",
  appId: "1:1066757692805:web:1b16883ffff6a515f3b8ec"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase.firestore();