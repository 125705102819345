import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import AppNavbar from "./AppNavbar";
import FirebaseService from "../services/FirebaseService";

class TeamEdit extends Component {
  emptyTeam = {
    id: "",
    teamname: "",
    teamstars: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      item: this.emptyTeam,
    };
    this.unsubscribe = undefined;
  }

  componentDidMount = () => {
    let id = this.props.match.params.id;
    if (id !== "new") {
      this.unsubscribe = FirebaseService.get(id).onSnapshot(this.onDataChange);
    }
  };

  componentWillUnmount = () => {
    if (this.unsubscribe !== undefined) {
      this.unsubscribe();
    }
  };

  onDataChange = (item) => {
    let data = item.data();
    let team = {
      id: item.id,
      teamname: data.teamname,
      teamstars: data.teamstars,
    };

    this.setState({
      item: team,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { item } = this.state;
    let id = this.props.match.params.id;
    if (id !== "new") {
      FirebaseService.update(id, item);
    } else {
      FirebaseService.addCustomer(item);
    }

    this.props.history.push("/teams");
  };

  render = () => {
    const { item } = this.state;
    const title = <h2>{item.id ? "Edit Team" : "Add Team"}</h2>;

    return (
      <div>
        <AppNavbar />
        <Container>
          {title}
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="teamname">Team Name</Label>
              <Input
                type="text"
                name="teamname"
                id="teamname"
                value={item.teamname || ""}
                onChange={this.handleChange}
                autoComplete="teamname"
              />
            </FormGroup>
            <FormGroup>
              <Label for="teamstars">teamstars</Label>
              <Input
                type="radio"
                name="teamstars"
                id="teamstars"
                value={item.teamstars || ""}
                onChange={this.handleChange}
                autoComplete="teamstars"
              />
            </FormGroup>

            <FormGroup tag="fieldset">
              <legend>Radio Buttons</legend>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio" />
                  {"5"}5
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio" />
                  {"4"}4
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio" />
                  {"3"}3
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio" />
                  {"2"}2
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio" />
                  {"1"}1
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
              <Button color="secondary" tag={Link} to="/customers">
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </Container>
      </div>
    );
  };
}

export default withRouter(TeamEdit);
