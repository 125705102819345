import firebase from '../util/firebase';

const db = firebase.collection("/teams");
let customers = [];

class FirebaseService {
  
  addTeam = (team) => {
    db.add(team);
  };

  getAll() {
    return db;
  }

  get(key) {
    return db.doc(key);
  }

  update(key, value) {
    return db.doc(key).update(value)
  }

  delete(key) {
    return db.doc(key).delete();
  }
}

export default new FirebaseService();